var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "list-view", staticClass: "me-list-menu" },
    [
      _c(
        "div",
        {
          staticClass: "me-list-menu__left",
          on: {
            click: function($event) {
              _vm.showRegion = false
            }
          }
        },
        [
          _c(
            "van-dropdown-menu",
            { attrs: { "active-color": "#33cc66" } },
            [
              _c(
                "van-dropdown-item",
                {
                  ref: "item",
                  attrs: { "title-class": "hidden", options: _vm.categories },
                  on: { change: _vm.handlerCateGoryChange },
                  model: {
                    value: _vm.curCategoryId,
                    callback: function($$v) {
                      _vm.curCategoryId = $$v
                    },
                    expression: "curCategoryId"
                  }
                },
                [
                  _c("span", { attrs: { slot: "title" }, slot: "title" }, [
                    _vm._v(_vm._s(_vm.curCategoryId))
                  ])
                ]
              )
            ],
            1
          ),
          _c("i", { staticClass: "me-icon-menu" }),
          _vm._v("\n    " + _vm._s(_vm.curCategoryName) + "\n  ")
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "me-list-menu__right",
          on: {
            click: function($event) {
              _vm.showRegion = !_vm.showRegion
            }
          }
        },
        [
          _vm._v("\n    " + _vm._s(_vm.curCountryName) + "\n    "),
          _c("i", { staticClass: "me-icon-location" })
        ]
      ),
      _c("TreeSelect", {
        style: { height: "70%" },
        attrs: { items: _vm.regions, show: _vm.showRegion },
        on: {
          closed: _vm.handlerChangeShowRegion,
          getCountry: _vm.handlerGetCountryCode
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }