<template>
  <div class="me-list" ref="list-box" v-scroll="{
    distance: 150,
    onTop: _onTop,
    onBottom: _onBottom
  }">
    <Header>
      <i slot="left-one" class="me-icon-back" @click="handlerResetGloblePramas()"></i>
      <i slot="center" v-if="type !== 'service'" :class="
        type === 'user'
          ? 'me-icon-user'
          : type === 'group'
          ? 'me-icon-group'
          : 'me-icon-company'
      "></i>
      <i slot="right-one" class="me-icon-search" @click="$router.push({name:'search'})"></i>
      <!-- v-if="!$store.state.keyword" -->
    </Header>
    <ListMenu class="list-menu" />
    <listTemp ref="list" :list="list" :type="type" v-if="list.length" class="list-margintop54" />
    <div class="empty" v-else>
      <i class="me-icon-empty"></i>
    </div>
  </div>
</template>
<script>
import Header from "@/components/business/h5/header";
import listTemp from "@/components/business/h5/list-item";
import ListMenu from "@/components/common/list-menu";
import {
  getUserList,
  getGroupList,
  getGroupListV5,
  getCompanyList,
  getServicesList
} from "@/api/webApi";

export default {
  name: "list-people",
  components: {
    Header,
    listTemp,
    ListMenu
  },
  data() {
    return {
      list: [],
      language: "",
      pageNum: 1,
      pageSize: 20,
      type: "",
      params: {},
      country: "",
      categoryId: "",
      loginUser: {}
    };
  },
  watch: {
    "$store.state.categoryId": {
      handler(val) {
        this.list = [];
        this.pageNum = 1;
        this.params.pageNum = 1;
        this.params.categoryId = val;
        this.handlerGetPageList();
      },
      deep: true
    },
    "$store.state.countryId": {
      handler(val) {
        this.list = [];
        this.pageNum = 1;
        this.params.pageNum = 1;
        this.params.country = val;
        this.handlerGetPageList();
      },
      deep: true
    }
  },
  created() {
    this.language =
      localStorage.getItem("langCode") !== null
        ? localStorage.getItem("langCode")
        : "1";
    const user = localStorage.getItem("userinfo");
    this.loginUser = this.$store.state.User.userinfo;
    this.type = this.$route.params.type || "";
    this.params = {
      language: this.language,
      country: this.$store.state.countryId || "",
      userId: this.loginUser.id || "",
      categoryId: this.$store.state.categoryId || "",
      searchKey: this.$store.state.keyword || "",
      pageNum: this.pageNum,
      pageSize: this.pageSize
    };
    this.handlerGetPageList();
  },
  // 进入当前页面之前给它设置滚动高度赋值
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === "detail") {
        console.log(111)
        vm.$el.scrollTop = to.meta.scollTopPosition;
      }
    });
  },
  // 离开页面得时候记录滚动高度
  beforeRouteLeave(to, from, next) {
    if (from.meta.scollTopPosition) {
      from.meta.scollTopPosition = this.$el.scrollTop;
    }
    next();
  },
  mounted() {
    this.scrollTo('list-box')
    this.scrollTo('list')
  },
  methods: {
    async scrollTo(key, val = "0px") {
      await this.$nextTick()
      if (this.$refs[key]) this.$refs[key].scrollTop = val;
    },
    handlerGetPageList() {
      switch (this.type) {
        case "user":
          this.handlerGetUserList();
          break;
        case "group":
          this.handlerGetGroupList();
          break;
        case "company":
        case 'enterprise':
          this.handlerGetCompanyList();
          break;
        case "service":
          this.handlerGetServicesList();
      }
    },
    handlerResetGloblePramas() {
      this.$store.dispatch("commitCateGoryId", 0);
      this.$store.dispatch("commitCountryId", 0);
      this.$router.go(-1);
    },
    _onTop() {
      console.log("go top");
    },
    _onBottom() {
      this.params.pageNum++;
      this.handlerGetPageList();
    },
    async handlerGetServicesList() {
      const result = await getServicesList(this.params).catch(e => { });
      if (result && result.data) {
        let data = result.data.map(item => {
          return {
            avatar: item.cover,
            name: item.i_can_do,
            skills: item.tags,
            id: item.id,
            actionData: {
              id: item.id,
              zanNum: item.like_count,
              replyCount: item.reviewer_count,
              likeState: item.like_id ? true : false,
              title: item.i_can_do,
              desc: item.detail,
              url: `${window.location.origin}/${item.id}/enterprise`,
              tags: item.tags
            }
          };
        });
        this.list = this.list.length ? this.list.concat(data) : data;
      }
    },
    async handlerGetCompanyList() {
      const result = await getCompanyList(this.params).catch(e => { });
      if (result && result.data) {
        let data = result.data.map(item => {
          return {
            avatar: item.enterprise_logo,
            name: item.enterprise_name,
            skills: item.enterprise_tag,
            id: item.id,
            actionData: {
              id: item.id,
              zanNum: item.like_count,
              replyCount: item.reviewer_count,
              likeState: item.like_id ? true : false,
              title: item.enterprise_name,
              desc: item.enterprise_info,
              url: `${window.location.origin}/detail/${item.id}/enterprise`,
              tags: item.enterprise_tag,
              type: "enterprise"
            }
          };
        });
        this.list = this.list.length ? this.list.concat(data) : data;
      }
    },
    //获取群列表
    async handlerGetGroupList() {
      const result = await getGroupListV5(this.params).catch(e => { });
      if (result && result.data.data) {
        let data = result.data.data.map(item => {
          return {
            avatar: item.groupHeader,
            name: item.groupName,
            skills: item.groupTag,
            id: item.groupId,
            actionData: {
              id: item.groupId,
              zanNum: item.likeCount,
              replyCount: item.reviewerCount,
              likeState: item.likeStatus ? true : false,
              title: item.groupName,
              desc: item.groupInfo,
              url: `${window.location.origin}/group/${item.groupId}`,
              tags: item.groupTag,
              type: "group"
            }
          };
        });
        this.list = this.list.length ? this.list.concat(data) : data;
      }
    },

    async handlerGetUserList() {
      const result = await getUserList(this.params).catch(e => { });
      if (result && result.data) {
        let data = result.data.map(item => {
          return {
            name: item.name,
            avatar: item.profile_photo,
            skills: item.skills,
            id: item.provider_id,
            actionData: {
              id: item.provider_id,
              zanNum: item.like_count,
              replyCount: item.reviewer_count,
              likeState: item.like_id ? true : false,
              type: "user",
              name: item.name
            }
          };
        });
        this.list = this.list.length ? this.list.concat(data) : data;
      }
    }
  }
};
</script>
<style lang="stylus" scoped>
@import '~styles/utils/index';

+b(me-list) {
  background: #ffffff;
  height: 90vh;
  overflow-y: scroll;
  margin: 54px 0 0;

  .list-margintop54 {
    margin: 54px 0 0;
  }

  .empty {
    margin-top: 10rem;
    font-size: 40px;
    color: #c2c2c2;
  }
}
</style>
