<template>
  <div class="me-list-menu" ref="list-view">
    <div class="me-list-menu__left" @click="showRegion = false">
      <van-dropdown-menu active-color="#33cc66">
        <van-dropdown-item
          v-model="curCategoryId"
          title-class="hidden"
          ref="item"
          :options="categories"
          @change="handlerCateGoryChange"
        >
          <span slot="title">{{ curCategoryId }}</span>
        </van-dropdown-item>
      </van-dropdown-menu>
      <i class="me-icon-menu"></i>
      {{ curCategoryName }}
    </div>

    <div class="me-list-menu__right" @click="showRegion = !showRegion">
      {{ curCountryName }}
      <i class="me-icon-location"></i>
    </div>
    <TreeSelect
      :items="regions"
      :show="showRegion"
      @closed="handlerChangeShowRegion"
      @getCountry="handlerGetCountryCode"
      :style="{height:'70%'}"
    />
  </div>
</template>
<script>
import TreeSelect from "@/components/business/treeSelect";
import { getCategories, fullRegion } from "@/api/webApi";
export default {
  name: "me-list-menu",
  data() {
    return {
      curCategoryId: "",
      categories: [],
      regions: [],
      language: "",
      showRegion: false,
      curCategoryName: "",
      country: 0,
      curCountryName: ""
    };
  },
  components: {
    TreeSelect
  },
  watch: {
    curCategoryId: {
      handler(val) {
        this.handlerGetCurCategoryName(val);
      },
      deep: true
    },
    country: {
      handler(val) {
        this.handlerGetCurCountryName();
      },
      deep: true
    }
  },
  async created() {
    this.language = localStorage.getItem("langCode") || "1";
    this.curCategoryId = this.$store.state.categoryId || "";
    this.country = this.$store.state.countryId
    await this.handlerGetCategories();
    await this.handlerGetFullRegion();
    this.handlerGetCurCategoryName(this.curCategoryId);
    this.handlerGetCurCountryName();
  },
  mounted(){
    this.scrollTo('list-view')
  },
  methods: {
    scrollTo(key,val = "0px") {
          this.$refs[key].scrollTop = val;
    },
    handlerGetCountryCode(code) {
      this.country = code;
      console.log(code, this.regions);
      this.handlerGetCurCountryName();
      this.$store.dispatch("commitCountryId", this.country);
    },
    handlerChangeShowRegion(status) {
      this.showRegion = status;
    },
    handlerCateGoryChange(category) {
      this.curCategoryId = category;
      this.handlerGetCurCategoryName(category);
      this.$store.dispatch("commitCateGoryId", category);
    },
    handlerGetCurCountryName() {
      if (this.country == 0) {
        this.curCountryName = "";
        return;
      }
      if (this.regions.length && this.country) {
        this.regions.some(item => {
          if (item.children && item.children.length)
            item.children.some(_el => {
              if (_el.value === this.country) this.curCountryName = _el.text;
            });
        });
      }
    },
    handlerGetCurCategoryName(category) {
      if (this.categories.length && category)
        this.categories.some(item => {
          if (item.value === category) {
            this.curCategoryName = item.text;
          }
        });
      else this.curCategoryName = "";
    },
    async handlerGetCategories() {
      let params = {
        language: this.language
      };
      const result = await getCategories(params);
      if (result) {
        this.categories = result.data.data.map(item => {
          return {
            text: item.category_name,
            value: item.category_id
          };
        });
        this.categories.unshift({ text: this.$t("all"), value: 0 });
      }
    },
    async handlerGetFullRegion() {
      let params = { language: this.language };
      const result = await fullRegion(params).catch(e => {});
      if (result)
        this.regions = result.data.data.map(item => {
          return {
            text: item.regionName,
            value: item.regionId,
            children: item.countries.map(el => {
              return {
                text: el.countryName,
                id: el.countryId,
                value: el.countryCode,
                phonePrefix: el.phonePrefix
              };
            })
          };
        });
      this.regions.unshift({ text: this.$t("all"), value: "" });
    }
  }
};
</script>
<style lang="stylus">
@import '~styles/utils/index';

+b(me-list-menu) {
  // padding 0 .9rem
  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f5f5f5;
  height: 50px;
  z-index: 5;
  position: fixed;
  background: #ffffff;
  width: 100%;
  font-size: 14px;

  i {
    // color #999999
    font-size: 14px;
    text-overflow: ellipsis;
    text-align: left;
    display: flex;
    flex-flow: row;
    align-items: center;

    &:before {
      font-size: 22px;
      margin-right: 0.25rem;
    }
  }

  +e(left) {
    display: flex;
    flex-flow: row;
    align-items: center;
    flex: 2;
    height: 50px;
    position: relative;
    padding-left: 0.9rem;
  }

  +e(right) {
    padding-right: 0.9rem;
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 50px;
    position: relative;
    flex: 2;
    justify-content: flex-end;
  }

  .me-popup {
    top: 104px !important;
  }

  .van-overlay {
    top: 104px !important;
  }

  .van-dropdown-menu {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    height: 40px;
    z-index: 99;
    background: none;

    &::after {
      border: 0;
    }
  }
}
</style>
