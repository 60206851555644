var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: {
            distance: 150,
            onTop: _vm._onTop,
            onBottom: _vm._onBottom
          },
          expression:
            "{\n  distance: 150,\n  onTop: _onTop,\n  onBottom: _onBottom\n}"
        }
      ],
      ref: "list-box",
      staticClass: "me-list"
    },
    [
      _c("Header", [
        _c("i", {
          staticClass: "me-icon-back",
          attrs: { slot: "left-one" },
          on: {
            click: function($event) {
              return _vm.handlerResetGloblePramas()
            }
          },
          slot: "left-one"
        }),
        _vm.type !== "service"
          ? _c("i", {
              class:
                _vm.type === "user"
                  ? "me-icon-user"
                  : _vm.type === "group"
                  ? "me-icon-group"
                  : "me-icon-company",
              attrs: { slot: "center" },
              slot: "center"
            })
          : _vm._e(),
        _c("i", {
          staticClass: "me-icon-search",
          attrs: { slot: "right-one" },
          on: {
            click: function($event) {
              return _vm.$router.push({ name: "search" })
            }
          },
          slot: "right-one"
        })
      ]),
      _c("ListMenu", { staticClass: "list-menu" }),
      _vm.list.length
        ? _c("listTemp", {
            ref: "list",
            staticClass: "list-margintop54",
            attrs: { list: _vm.list, type: _vm.type }
          })
        : _c("div", { staticClass: "empty" }, [
            _c("i", { staticClass: "me-icon-empty" })
          ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }